import React, { useState, useRef } from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"

const TimelineBlock = ({ block }) => {
  const [activeItem, setActiveItem] = useState(0)

  const refContainers = useRef([])

  const refContainersCollection = (el) => {
    if (el && !refContainers.current.includes(el)) {
      refContainers.current.push(el)
    }
  }

  return (
    <BlockWrapper
      className="relative overflow-x-hidden overflow-y-visible text-white bg-primary"
      blockWidth="screen"
      block={block}
      blockPadding=""
      showHeadline="false"
    >
      <div
        className={`top-0 left-0 z-10 w-full px-8 ${
          block.image?.filename?.length > 0 ? "absolute" : "md:absolute"
        } bg-gradient-to-b from-primary`}
      >
        <div className="max-w-screen-lg mx-auto">
          <HeadlineEnhanced
            className="text-white pt-block"
            headline={block.headline}
          />
        </div>
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="relative w-full md:w-1/2">
          {block.image?.filename?.length > 0 && (
            <Image
              className="object-cover w-full h-full mix-blend-soft-light"
              image={block?.image}
              forceLoad={true}
            />
          )}
        </div>
        <div className="w-full pl-8 pr-8 md:w-1/2 md:pl-0">
          <div className="w-full md:max-w-[512px] mr-auto pt-block">
            <div
              className="relative flex flex-row w-2/3 py-2 ml-auto transition-transform duration-300 md:w-full md:mt-28"
              style={{
                transform: `translateX( -${refContainers?.current[activeItem]?.offsetLeft}px)`,
              }}
            >
              <span className="absolute left-0 right-0 block h-1 bg-primary-700"></span>
              <span className="absolute block w-[300vw] h-1 bg-primary-700 right-full"></span>
              <span className="absolute block w-[300vw] h-1 bg-primary-700 left-full"></span>
              {block.timeline_items?.length > 0 &&
                block.timeline_items.map(
                  (item, index) =>
                    item.date?.length > 0 && (
                      <span
                        key={index}
                        ref={refContainersCollection}
                        className="relative"
                      >
                        <span
                          className={`absolute block w-full h-1 z-20 ${
                            index === activeItem ? "bg-white" : "bg-primary-700"
                          }`}
                        ></span>
                        <button
                          className={`px-grid py-2 text-xl lg:text-2xl whitespace-nowrap ${
                            index === activeItem ? "font-bold" : ""
                          }`}
                          onClick={() => setActiveItem(index)}
                        >
                          {item.date}
                        </button>
                      </span>
                    )
                )}
            </div>
            <div className="mb-4 overflow-hidden lg:my-8">
              <div
                className="flex flex-row w-full flex-nowrap"
                style={{
                  transform: `translateX( -${activeItem * 100}%)`,
                }}
              >
                {block.timeline_items?.length > 0 &&
                  block.timeline_items.map(
                    (item, index) =>
                      item.date?.length > 0 && (
                        <div className="w-full min-w-full pl-4">
                          <Richtext className="block w-full" text={item.text} />
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default TimelineBlock
